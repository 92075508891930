/* eslint-disable*/
import VueStoreService from './VueStoreService';
const SpecialityLessonService = {
    async getSpecialityLessons(filter,store){
        var resp = await VueStoreService.get("speciality_lesson"+filter, store)
        return resp;
    },
    async saveSpecialityLesson(data,store){
        var resp = await VueStoreService.post("speciality_lesson",data,store)
        return resp;
    },
    async getSpecialityLessonById(specialitylesson_id,store){
        var resp = await VueStoreService.get( "speciality_lesson/"+specialitylesson_id,store)
        return resp;
    },

    async updateSpecialityLesson(specialitylesson_id,data,store){
        var resp = await VueStoreService.put("speciality_lesson/"+specialitylesson_id,data,store)
        return resp;
    },

    async deleteSpecialityLesson(specialitylesson_id,store){
        var resp = await VueStoreService.delete("speciality_lesson/"+specialitylesson_id,store)
        return resp;
    },

}

export default SpecialityLessonService;