/* eslint-disable*/
import VueStoreService from './VueStoreService';
const TypificationService = {
    async getTypifications(filter,store){
        var resp = await VueStoreService.get("typification_lesson"+filter, store)
        return resp;
    },
    async saveTypification(data,store){
        var resp = await VueStoreService.post("typification_lesson",data,store)
        return resp;
    },
    async getTypificationById(typification_id,store){
        var resp = await VueStoreService.get( "typification_lesson/"+typification_id,store)
        return resp;
    },

    async updateTypification(typification_id,data,store){
        var resp = await VueStoreService.put("typification_lesson/"+typification_id,data,store)
        return resp;
    },

    async deleteTypification(typification_id,store){
        var resp = await VueStoreService.delete("typification_lesson/"+typification_id,store)
        return resp;
    },

}

export default TypificationService;