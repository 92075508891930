/* eslint-disable*/
import VueStoreService from './VueStoreService';
const DivisionService = {
    async getDivisions(filter,store){
        var resp = await VueStoreService.get("division_lesson"+filter, store)
        return resp;
    },
    async saveDivision(data,store){
        var resp = await VueStoreService.post("division_lesson",data,store)
        return resp;
    },
    async getDivisionById(division_id,store){
        var resp = await VueStoreService.get("division_lesson/"+division_id,store)
        return resp;
    },

    async updateDivision(division_id,data,store){
        var resp = await VueStoreService.put("division_lesson/"+division_id,data,store)
        return resp;
    },

    async deleteDivision(division_id,store){
        var resp = await VueStoreService.delete("division_lesson/"+division_id,store)
        return resp;
    },

}

export default DivisionService;