/* eslint-disable*/
import VueStoreService from './VueStoreService';
const AreasService = {
    async getAreas(filter,store){
        var resp = await VueStoreService.get("area"+filter, store)
        return resp;
    },
    async saveArea(data,store){
        var resp = await VueStoreService.post("area",data,store)
        return resp;
    },
    async getAreaById(area_id,store){
        var resp = await VueStoreService.get( "area/"+area_id,store)
        return resp;
    },
    async updateArea(area_id,data,store){
        var resp = await VueStoreService.put("area/"+area_id,data,store)
        return resp;
    },
    async validateAreaCategory (area_id,store){
        var resp = await VueStoreService.get("area/canDeleteArea/" + area_id,store)
        return resp;
    },
    async deleteArea(area_id,store){
        var resp = await VueStoreService.delete("area/"+area_id,store)
        return resp;
    },
}

export default AreasService;