/* eslint-disable*/
import VueStoreService from './VueStoreService';
const TypeWorkService = {
    async getTypeWorks(filter,store){
        var resp = await VueStoreService.get("type_work_lesson"+filter, store)
        return resp;
    },
    async saveTypeWork(data,store){
        var resp = await VueStoreService.post("type_work_lesson",data,store)
        return resp;
    },
    async getTypeWorkById(typework_id,store){
        var resp = await VueStoreService.get( "type_work_lesson/"+typework_id,store)
        return resp;
    },

    async updateTypeWork(typework_id,data,store){
        var resp = await VueStoreService.put("type_work_lesson/"+typework_id,data,store)
        return resp;
    },

    async deleteTypeWork(typework_id,store){
        var resp = await VueStoreService.delete("type_work_lesson/"+typework_id,store)
        return resp;
    },

}

export default TypeWorkService;